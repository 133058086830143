<template>
  <v-dialog
    v-model="releaseModalData.dialog"
    max-width="1650px"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form v-model="formValid" ref="form">
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-btn
                  v-if="
                    (permissionCan('create') && !formModel.id) ||
                    permissionCan('update')
                  "
                  color="primary"
                  dark
                  class="mb-2"
                  @click="handleSaveModalForm"
                >
                  Release
                </v-btn>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <div v-if="formModel.owner_name">
                  <p>User: {{ formModel.owner_name }}</p>
                  <p>Created at: {{ formModel.created_at }}</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" sm="5" md="5">
                <v-select
                  v-model="formModel.storage_id"
                  :items="storageCollectionAll"
                  :rules="requiredRules"
                  label="Select storage"
                  item-text="name"
                  item-value="id"
                  :id="dynamicID"
                  :error-messages="messages['storage_id']"
                  @keyup="messages['storage_id'] = ''"
                  :disabled="
                    (formModel.computedEntities &&
                      formModel.computedEntities.length > 0) ||
                    formModel.id
                  "
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-divider vertical></v-divider>
              <v-spacer></v-spacer>
              <v-col cols="5" sm="5" md="5">
                <v-combobox
                  v-if="!formModel.id && formModel.storage_id"
                  :items="itemCollection"
                  v-model="itemInputData.item"
                  item-text="ReferenceName"
                  :label="searchInpuText"
                  :return-object="true"
                  :disabled="isEditedItemData"
                  :error-messages="messages['item']"
                  @input="handleInputItemSearch"
                  :id="dynamicID"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No item matching! Press <kbd>enter</kbd> to search
                          serial number
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- <v-text-field
                  v-model="searchEntity"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field> -->

                <v-data-table
                  v-if="!formModel.id"
                  :headers="headersEntity"
                  :items="entitiesArray"
                  v-model="selectedEntities"
                  item-key="item_entity_id"
                  class="elevation-1"
                  group-by="Pcs"
                  show-group-by
                  show-select
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Select entities</v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>

                  <template
                    v-slot:group.header="{
                      group,
                      headers,
                      toggle,
                      isOpen,
                      items,
                    }"
                  >
                    <td :colspan="headers.length">
                      <div class="d-flex flex-row pa-0">
                        <v-btn
                          class="mt-3 mb-0"
                          @click="toggle"
                          small
                          icon
                          :ref="group"
                          :data-open="isOpen"
                        >
                          <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                          <v-icon v-else>mdi-chevron-down</v-icon>
                        </v-btn>

                        <v-checkbox
                          v-model="groupChkBox[items[0].item_entity_id]"
                          dense
                          :label="group"
                          class="ml-3 mt-2 mb-0"
                          @change="handleCheckGroup($event, items)"
                        ></v-checkbox>
                      </div>
                    </td>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-form
                  v-model="formEditItemValid"
                  ref="formEditItem"
                  v-if="!formModel.id"
                >
                  <b>Total quantity:</b> {{ totalQuantityEntities }}
                  {{ totalQuantityUnit }}
                  <v-row>
                    <v-col cols="2" sm="2" md="2">
                      <v-select
                        v-if="
                          adjustmentStatuses &&
                          adjustmentStatuses.stock &&
                          statuses.system &&
                          !formModel.id &&
                          formModel.storage_id
                        "
                        v-model="editedItem.releaseStatus"
                        :items="adjustmentStatuses.stock['release_itemEntity']"
                        label="State"
                        item-text="translated"
                        item-value="key"
                        :rules="requiredRules"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" sm="2" md="2">
                      <v-checkbox
                        v-if="editedItem.item_entity_id"
                        v-model="showRemainder"
                        label="Remainder"
                        value="3"
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row v-if="showRemainder">
                    <v-col cols="1" sm="1" md="1">
                      <v-text-field
                        v-if="editedItem.item_entity_id"
                        v-model="editedItem.quantity"
                        label="Quantity"
                        :rules="maxQuantityRules"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="1" sm="2" md="2">
                      <v-text-field
                        v-if="editedItem.item_entity_id"
                        v-model="editedItem.serial_number"
                        label="Serial number"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="1" sm="2" md="2">
                      <v-checkbox
                        v-if="editedItem.item_entity_id"
                        v-model="editedItem.status"
                        label="Receive remainder imediately"
                        value="4"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" sm="2" md="2">
                      <v-btn
                        v-if="
                          (selectedEntities.length > 0 &&
                            permissionCan('create') &&
                            formModel.storage_id &&
                            !formModel.id) ||
                          (permissionCan('update') &&
                            formModel.storage_id &&
                            !formModel.id)
                        "
                        color="primary"
                        dark
                        class="mb-2"
                        @click="handleAddItemToList"
                      >
                        Add to release list
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>

            <row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  :headers="headersReleaseEntity"
                  :items="computedEntities"
                  class="elevation-1"
                  group-by="Pcs"
                  show-group-by
                  hide-default-footer
                  :items-per-page="-1"
                >
                  <template
                    v-slot:group.header="{ group, headers, toggle, isOpen }"
                  >
                    <td :colspan="headers.length">
                      <v-btn
                        @click="toggle"
                        small
                        icon
                        :ref="group"
                        :data-open="isOpen"
                      >
                        <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                      </v-btn>
                      {{ group }}
                    </td>
                  </template>
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Selected entities</v-toolbar-title>
                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="text-h5"
                            >Are you sure you want to delete this
                            item?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeDelete"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItemConfirm"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:item.name="{ item }">
                    <span> {{ item.nameTranslated }}</span>
                  </template>

                  <template v-slot:item.reference="{ item }">
                    <span> {{ item.reference_number }}</span>
                  </template>

                  <template v-slot:item.quantityUnit="{ item }">
                    <span> {{ item.quantityUnitTranslated }}</span>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      @click="deleteItem(item)"
                      v-if="!formModel.id"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
                <span
                  v-if="messages['entities'] && messages['entities'].length > 0"
                  style="color: red"
                  >{{ messages["entities"] }}</span
                >
                <v-textarea
                  :disabled="formModel.id"
                  v-model="formModel.comment"
                  label="Comment"
                />
              </v-col>
            </row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { ENDPOINT } from "./Releases";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
// import Form from "../../vue-bootstrap/Form.vue";

const TRANSLATED_ATRIBUTES = [];

var today = new Date();
var dd = today.getDate();

var mm = today.getMonth() + 1;
var yyyy = today.getFullYear();
if (dd < 10) {
  dd = "0" + dd;
}

if (mm < 10) {
  mm = "0" + mm;
}
today = yyyy + "-" + mm + "-" + dd;

export const initialReleaseFormData = () => ({
  id: null,
  storage_id: null,
  scheduled_release_date: null,
  scheduled_receive_date: null,
  comment: "",
  owner_id: null,
  owner_name: null,
  created_at: null,
  entities: [],
  release_entities: [],
  adjustment_entities: [],
});

export const initialItemInputData = {
  // item_entity_id: null,
  // item: null,
  // quantityUnit: null,
  // serial_number: null,
  // quantity: null,
  // old_status: null,
  status: {
    key: 4,
    value: "on_hand",
    translated: "On hand",
  },
};

export const initialAddItemInputData = {
  serial_number: null,
  quantity: 0,
  status: null,
  releaseStatus: 6,
};

export default {
  name: "ReleaseModalForm",
  props: ["releaseModalData", "receivedData"],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialReleaseFormData()),
      itemInputData: Object.assign({}, initialItemInputData),
      editedItem: Object.assign({}, initialAddItemInputData),
      formValid: false,
      formItemValid: false,
      formEditItemValid: false,
      showRemainder: false,
      maxQuantity: 10,
      editedIndex: null,
      dialogDelete: false,
      entityModal: false,
      entitySelected: null,
      entitiesArray: [],
      entitiesDeletedArray: [],
      selectedEntities: [],
      cancelItem: null,
      groupChkBox: [],
      permissions: [],
      headersEntity: [
        { text: "Pcs", value: "Pcs" },
        { text: "ID", value: "item_entity_id", groupable: false },
        { text: "Reference", value: "reference_number", groupable: false },
        { text: "Item name", value: "nameTranslated", groupable: false },
        { text: "Serial number", value: "serial_number", groupable: false },
        { text: "Status", value: "statusTranslated", groupable: false },
        {
          text: "Original quantity",
          value: "original_quantity",
          groupable: false,
        },
        { text: "Quantity", value: "quantity", groupable: false },
        {
          text: "Quantity unit",
          value: "quantityUnitTranslated",
          groupable: false,
        },
        { text: "Storage", value: "storage", groupable: false },
        { text: "Reserved at", value: "reserved_at", groupable: false },
        { text: "Reserved by", value: "reserved_by_name", groupable: false },
      ],
      headersReleaseEntity: [
        { text: "Pcs", value: "Pcs" },
        { text: "ID", value: "item_entity_id", groupable: false },
        { text: "Reference", value: "reference_number", groupable: false },
        { text: "Item name", value: "nameTranslated", groupable: false },
        { text: "Serial number", value: "serial_number", groupable: false },
        { text: "Status", value: "statusTranslated", groupable: false },
        {
          text: "Original quantity",
          value: "original_quantity",
          groupable: false,
        },
        { text: "Quantity", value: "quantity", groupable: false },
        {
          text: "Quantity unit",
          value: "quantityUnitTranslated",
          groupable: false,
        },
        { text: "Storage", value: "storage", groupable: false },
        {
          text: "Remainder serial number",
          value: "remainder_serial_number",
          groupable: false,
        },
        {
          text: "Remainder quantity",
          value: "remainder_quantity",
          groupable: false,
        },

        {
          text: "Remainder status",
          value: "remainderStatusTranslated",
          groupable: false,
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          groupable: false,
        },
      ],

      headersComponent: [
        { text: "ID", value: "item_entity_id" },
        { text: "Reference", value: "reference_number" },
        { text: "Component name", value: "nameTranslated" },
        { text: "Quantity", value: "quantity" },
        { text: "Quantity unit", value: "quantityUnitTranslated" },
      ],

      headers: [
        { text: "ID", value: "item_entity_id" },
        { text: "Reference", value: "reference" },
        { text: "Item name", value: "name" },
        { text: "Serial number", value: "serial_number" },
        { text: "Quantity", value: "quantityWithUnit" },
        // { text: "Quantity unit", value: "quantityUnit" },
        { text: "Action", value: "actions" },
      ],

      messages: {},
      stringRules: [
        (v) => !!v || "Field is required",
        (v) => v.length < 25 || "Field must be max 16 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      maxQuantityRules: [
        (v) =>
          v < this.maxQuantity || "Field must be less than " + this.maxQuantity,
      ],
    };
  },
  computed: {
    ...mapGetters([
      "statuses",
      "types",
      "itemCollection",
      "storageCollectionAll",
    ]),

    computedEntities() {
      // console.log(
      //   this.formModel.release_entities,
      //   this.formModel.adjustment_entities
      // );

      this.formModel.release_entities.map((item) => {
        let remainderItem = this.formModel.adjustment_entities.find(
          (adjustmentItem) => {
            return item.item_entity_id == adjustmentItem.item_entity_id;
          }
        );
        if (remainderItem) {
          item.remainder_serial_number = remainderItem.serial_number;
          item.remainder_quantity = remainderItem.quantity;
          item.remainderStatusTranslated = this.getTranslatedStatus(
            remainderItem.status
          );
        }
        return item;
      });
      // return this.formModel.release_entities;
      return this.calcEntityPcs(this.formModel.release_entities);
    },

    destinationStorageCollection() {
      if (!this.storageCollectionAll) {
        return [];
      }
      if (!this.formModel.storage_id) return this.storageCollectionAll;

      return this.storageCollectionAll.filter((item) => {
        return item.id != this.formModel.storage_id;
      });
    },

    totalQuantityEntities() {
      return this.selectedEntities.reduce(function (a, b) {
        return a + b.quantity;
      }, 0);
    },

    totalQuantityUnit() {
      if (
        this.selectedEntities.length == 0 ||
        !this.selectedEntities[0].quantity_unit_id
      )
        return "";
      return this.$store.getters.getQuantityUnitByID(
        this.selectedEntities[0].quantity_unit_id
      ).name;
    },

    adjustmentStatuses() {
      if (
        this.statuses &&
        this.statuses.stock &&
        this.statuses.stock["release_itemEntity"]
      ) {
        let statuses = this.statuses;

        this.statuses.stock["release_itemEntity"].map((item) => {
          item.translated = this.getTranslatedStatus(item.key);
          return item;
        });
        return statuses;
      }
      return [];
    },

    statusesEdit() {
      return this.statuses.stock.itemEntity;
    },

    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? "View release" + " - " + this.formModel.rl_number
        : "New release";
    },

    searchInpuText() {
      return "Search entity: reference, item name, project, so";
    },
  },

  watch: {
    "releaseModalData.dialog": {
      deep: true,
      handler(value) {
        if (value && !this.releaseModalData.editedId) {
          this.itemInputData = Object.assign({}, initialItemInputData);
          this.formModel = Object.assign({}, initialReleaseFormData());
          this.formModel.scheduled_release_date = today;
          this.formModel.scheduled_receive_date = today;

          if (this.receivedData && this.receivedData.storage_id) {
            this.formModel.storage_id = this.receivedData.storage_id;
            this.itemInputData.item = this.receivedData.trNumber;
            this.handleInputItemSearch(this.receivedData.trNumber);
          }
        }
        setTimeout(this.resetErrorMessages(), 1200, this.resetErrorMessages());
      },
    },

    "releaseModalData.editedId": {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        if (value) {
          ApiService.get(ENDPOINT + value)
            .then(({ data }) => {
              // for (var entity of data.entities) {
              //   entity.item = this.$store.getters.getItemByID(entity.item_id);
              //   entity.item.reference_number = entity.reference_number;
              //   entity.item.serial_number = entity.serial_number;
              //   // entity.quantity = entity.quantity;
              //   entity.oldStatusTranslated = this.getTranslatedStatus(
              //     entity.old_status
              //   );
              //   entity.statusTranslated = this.getTranslatedStatus(
              //     entity.new_status
              //   );
              //   entity.nameTranslated = this.$helpers.getTranslated(
              //     entity.translations
              //   ).name;
              //   entity.item.quantity_unit_id = entity.quantity_unit_id;
              //   // entity.item.comment = entity.comment;
              //   entity.quantityUnitTranslated =
              //     this.$store.getters.getQuantityUnitByID(
              //       entity.quantity_unit_id
              //     ).name;
              // }
              this.entitiesDeletedArray = [];
              data.release_entities.forEach((item) => {
                item.nameTranslated = this.$helpers.getTranslated(
                  item.translations
                ).name;
                item.statusTranslated = this.getTranslatedStatus(item.status);

                item.quantityUnitTranslated =
                  this.$store.getters.getQuantityUnitByID(
                    item.quantity_unit_id
                  ).name;
              });

              data.adjustment_entities.forEach((item) => {
                item.nameTranslated = this.$helpers.getTranslated(
                  item.translations
                ).name;
                item.statusTranslated = this.getTranslatedStatus(item.status);

                item.quantityUnitTranslated =
                  this.$store.getters.getQuantityUnitByID(
                    item.quantity_unit_id
                  ).name;
              });

              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              setTimeout(() => {
                this.closeAll();
              }, 200);
            })
            .catch((error) => {
              console.log("Error!: ", error);
            });
        }
        this.resetErrorMessages();
      },
    },

    selectedEntities: function (val) {
      if (val.length == 1) {
        this.editedItem = Object.assign({}, val[0]);
        this.maxQuantity = this.editedItem.quantity - 0.0001;
        this.editedItem.quantity = 0;
        this.editedItem.status = 3;
      } else {
        this.editedItem = Object.assign({}, initialAddItemInputData);
        this.maxQuantity = 10;
      }
    },
  },
  methods: {
    ...mapActions(["fetchStorage", "fetchItem", "fetchQuantityUnit"]),

    handleResetForm() {
      this.entitiesArray = [];
      this.selectedEntities = [];
      this.editedItem = Object.assign({}, initialAddItemInputData);
      this.maxQuantity = 10;
      this.formModel = Object.assign({}, initialReleaseFormData());
      this.itemInputData = Object.assign({}, initialItemInputData);
      this.editedItem = Object.assign({}, initialAddItemInputData);
      this.entitiesDeletedArray = [];
      this.groupChkBox = [];
    },

    handleCloseModalForm() {
      let data = this.computedEntities.map((item) => {
        return item.item_entity_id;
      });

      if (!this.formModel.id && data.length > 0) {
        this.unReserveEntityByIds({
          ids: data,
        });
      }
      this.handleResetForm();
      this.$emit("closeModalForm");
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        // this.modalData.loading = true;

        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            if (
              model.translations[langCode] &&
              model.translations[langCode].name != ""
            ) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
          if (Object.keys(translations).length != 0) {
            model.translations = translations;
          }
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(ENDPOINT, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    handleInputItemSearch(value) {
      this.entitySelected = null;
      if (value && typeof value === "object") {
        this.openEntityModalForm({
          storage_id: this.formModel.storage_id,
          statuses: [4, 9],
          item_id: this.itemInputData.item.id,
        });
      } else if (value != "") {
        this.itemCollection = [];
        this.openEntityModalForm({
          s: value,
          storage_id: this.formModel.storage_id,
          statuses: [4, 9],
        });
      }
    },

    openEntityModalForm(searchObj) {
      ApiService.post(ENDPOINT + "itemEntity/search", searchObj)
        .then((data) => {
          this.selectedEntities = [];
          if (data.data.length > 0) {
            let dataTranslated = data.data.map((item) => {
              item.nameTranslated = this.$helpers.getTranslated(
                item.translations
              ).name;
              item.quantityUnitTranslated = this.$helpers.getTranslated(
                item.quantity_unit_translations
              ).name;
              item.statusTranslated = this.getTranslatedStatus(item.status);
              item.isSelectable = !item.reserved_at;
              item.Pcs = this.getItemPcs(item);
              item.show = true;
              return item;
            });

            // this.entitiesArray = dataTranslated;
            this.entitiesArray = this.calcEntityPcs(dataTranslated);

            setTimeout(() => {
              this.closeAll();
            }, 200);
          } else {
            Swal.fire({
              title: "",
              text: "Entity not found!",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    getItemPcs(item) {
      return (
        " " +
        item.reference_number +
        " " +
        item.nameTranslated +
        " " +
        item.quantity +
        item.quantityUnitTranslated
      );
    },

    calcEntityPcs(entities) {
      if (!entities || typeof entities == "undefined") {
        return [];
      }
      let pcsArray = [];

      entities = entities.map((item) => {
        item.Pcs = this.getItemPcs(item);
        if (pcsArray.indexOf(item.Pcs) === -1) {
          pcsArray.push(item.Pcs);
        }
        return item;
      });

      pcsArray.forEach((pcs) => {
        let sum = 0;
        entities
          .filter((item) => {
            if (item.Pcs == pcs) sum++;
            return item.Pcs == pcs;
          })
          .map((item) => {
            item.Pcs = item.Pcs + " - " + sum + " pcs";
            return item;
          });
      });

      return entities;
    },

    handleClickEntity(entity) {
      this.unReserveEntityByIds({
        ids: [entity.item_entity_id],
      });
      this.itemInputData = Object.assign({}, entity);
      this.itemInputData.status = 4;

      let editedIndex = this.formModel.entities.indexOf(entity);
      this.cancelItem = Object.assign({}, entity);
      this.formModel.entities.splice(editedIndex, 1);
    },

    handleAddToListSelected() {
      let entityIds = this.selectedEntities.map((entity) => {
        return entity.item_entity_id;
      });

      if (this.selectedEntities.length > 0) {
        this.formModel.entities = [
          ...this.formModel.entities,
          ...this.selectedEntities.map((entity) => {
            entity.old_status = entity.status;
            entity.status = 4;
            entity.statusTranslated = this.getTranslatedStatus(entity.status);
            entity.oldStatusTranslated = this.getTranslatedStatus(
              entity.old_status
            );
            return entity;
          }),
        ];

        this.reserveEntityByIds({
          ids: entityIds,
        });
        this.entityModal = false;
        this.cancelItem = null;
        this.itemInputData = Object.assign({}, initialItemInputData);
        this.selectedEntities = [];
      }
    },

    handleCancelItem() {
      this.formModel.entities.push(this.cancelItem);
      this.itemInputData = Object.assign({}, initialItemInputData);
      this.reserveEntityByIds({
        ids: [this.cancelItem.item_entity_id],
      });
      this.cancelItem = null;
      this.$refs.formItem.resetValidation();
    },

    resetInpuDataForm() {
      this.entitiesArray = [];
      this.entitySelected = null;
      this.itemInputData = Object.assign({}, initialItemInputData);
    },

    getTranslatedStatus(id) {
      return this.$t("ADJUSTMENT.status_" + id);
    },

    setTranslatedAttributes() {
      if (!this.formModel.id) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },

    handleAddItemToList() {
      this.$refs.formEditItem.validate();

      if (this.formEditItemValid) {
        let self = Object.assign({}, this);

        if (self.selectedEntities.length == 1) {
          this.reserveEntityByIds({
            ids: [this.editedItem.item_entity_id],
          })
            .then(() => {
              let releaseObject = Object.assign({}, self.selectedEntities[0]);

              this.entitiesDeletedArray.push(Object.assign({}, releaseObject));

              this.entitiesArray = this.calcEntityPcs(
                this.entitiesArray.filter((filterItem) => {
                  return (
                    releaseObject.item_entity_id != filterItem.item_entity_id
                  );
                })
              );

              releaseObject.quantity =
                releaseObject.quantity - self.editedItem.quantity;

              if (releaseObject.quantity != parseInt(releaseObject.quantity)) {
                releaseObject.quantity = releaseObject.quantity.toFixed(4);
              }

              releaseObject.status = self.editedItem.releaseStatus;
              // releaseObject.quantity - self.editedItem.quantity;

              releaseObject.statusTranslated = this.getTranslatedStatus(
                releaseObject.status
              );

              self.formModel.release_entities.push(releaseObject);
              // console.log("self.editedItem ", self.editedItem);
              if (!self.editedItem.status) {
                self.editedItem.status = 3;
              }

              self.editedItem.statusTranslated = this.getTranslatedStatus(
                self.editedItem.status
              );

              if (self.editedItem.quantity > 0) {
                self.formModel.adjustment_entities.push(
                  Object.assign({}, self.editedItem)
                );
              }
              this.selectedEntities = [];
              self.editedItem = Object.assign({}, initialAddItemInputData);
              this.groupChkBox = [];
            })
            .catch(() => {
              Swal.fire({
                title: "",
                text: "The entity is reserved!",
                icon: "error",
              });
            });
        } else {
          let ids = [];

          let selectedEntities = self.selectedEntities.map((item) => {
            let clonedItem = Object.assign({}, item);
            ids.push(clonedItem.item_entity_id);
            clonedItem.status = self.editedItem.releaseStatus;
            clonedItem.statusTranslated = this.getTranslatedStatus(
              clonedItem.status
            );
            return clonedItem;
          });
          this.reserveEntityByIds({
            ids: ids,
          }).then((data) => {
            if (data) {
              self.formModel.release_entities =
                self.formModel.release_entities.concat(selectedEntities);
              self.editedItem = Object.assign({}, initialAddItemInputData);

              this.entitiesDeletedArray = this.entitiesArray.filter(
                (filterItem) => {
                  return ids.includes(filterItem.item_entity_id);
                }
              );

              this.entitiesArray = this.calcEntityPcs(
                this.entitiesArray.filter((filterItem) => {
                  return !ids.includes(filterItem.item_entity_id);
                })
              );

              this.selectedEntities = [];
              this.groupChkBox = [];
            } else {
              Swal.fire({
                title: "",
                text: "The entity is reserved!",
                icon: "error",
              });
            }
          });
        }

        this.entitySelected = null;
        this.itemInputData = Object.assign({}, initialItemInputData);
        this.$refs.formEditItem.resetValidation();
      }
    },

    resetItemInputData() {
      this.itemInputData.item = null;
      this.itemInputData.quantityUnit = null;
      this.itemInputData.serial_number = null;
      this.itemInputData.quantity = null;
      this.itemInputData.status = null;
      this.itemInputData.old_status = null;
      this.cancelItem = null;
    },

    reserveEntityByIds(entityIds) {
      return ApiService.post(ENDPOINT + "itemEntity/reserve", entityIds);
    },

    unReserveEntityByIds(entityIds) {
      ApiService.post(ENDPOINT + "itemEntity/unreserve", entityIds)
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    deleteItem(item) {
      this.editedIndex = this.computedEntities.indexOf(item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let itemToDelete = this.computedEntities[this.editedIndex];

      let restoredItem = Object.assign(
        {},
        this.entitiesDeletedArray.find(
          (item) => item.item_entity_id == itemToDelete.item_entity_id
        )
      );

      // console.log(restoredItem, itemToDelete);

      this.entitiesArray.push(restoredItem);

      this.entitiesArray = this.calcEntityPcs(this.entitiesArray);

      this.unReserveEntityByIds({
        ids: [itemToDelete.item_entity_id],
      });

      this.formModel.release_entities = this.formModel.release_entities.filter(
        (item) => {
          return item.item_entity_id != itemToDelete.item_entity_id;
        }
      );
      this.formModel.adjustment_entities =
        this.formModel.adjustment_entities.filter((item) => {
          return item.item_entity_id != itemToDelete.item_entity_id;
        });
      // this.formModel.entities.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] && this.$refs[k].$attrs["data-open"]) {
          this.$refs[k].$el.click();
        }
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = null;
      });
    },

    handleCheckGroup(event, items) {
      let itemIds = items.map((i) => i.item_entity_id);

      this.selectedEntities = this.selectedEntities.filter((i) => {
        return !itemIds.includes(i.item_entity_id);
      });
      if (event) {
        this.selectedEntities = this.selectedEntities.concat(items);
      }
    },

    resetErrorMessages() {
      let errorData = Object.assign({}, initialReleaseFormData());

      if (this.$refs.form) this.$refs.form.resetValidation();

      this.languages.forEach((language) => {
        TRANSLATED_ATRIBUTES.forEach((attribute) => {
          errorData["translations." + language.lang + "." + attribute] = "";
        });
      });
      this.messages = errorData;
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("release") > -1
        );
      }
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == "stock.release." + action);
    },
  },

  async mounted() {
    this.setPermissions();
    this.fetchItem();
    this.fetchStorage();
    this.fetchQuantityUnit();
    this.setTranslatedAttributes();
  },
};
</script>
